<template>
    <div class="modal is-active">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Usuń kategorię</p>
          <button class="delete" aria-label="close" @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <!-- Your form for adding a new category goes here -->
          <!-- Example form -->
          <form @submit.prevent="submitForm">
            <div class="field">
              <p>Czy na pewno chcesz usunąć kategorię <strong>{{ category.name }}</strong>?</p>
              <hr>
              <p><strong>UWAGA!</strong></p>
              <p>Usunięcie tej kategorii oznacza równie usunięcie wszystkich należących do niej produktów!</p>
              <hr>
            </div>
            <button type="button" class="button is-success is-light is-outlined mr-4" @click="submitForm">Usuń</button>
            <button type="button" class="button is-danger is-light is-outlined " @click="cancelForm">Anuluj</button>
          </form>
        </section>
      </div>
    </div>
</template>
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
	props: {
		categoryID: Number
	},
    data() {
        return {
            category: {},
        };
    },
    mounted() {
      	this.getCategory()
    },
    methods: {
        getCategory() {
            axios
                .get(`/api/v1/categories/${this.categoryID}`)
                .then(response => {
                    this.category = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        submitForm() {
            axios
                .delete(`/api/v1/categories/${this.categoryID}/`)
                .then(response => {
                    toast({
                        message: 'Kategoria została usunięta',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
					this.$emit('submit', this.category)
					this.$emit('close')
                })
                .catch(error => {
                    console.log(error.response)
                })
        },
		cancelForm() {
			this.$emit('close')
		}
    }
};
</script>

<style>
    .modal-card-body {
      	border-bottom-left-radius: 6px;
      	border-bottom-right-radius: 6px;
    }
</style>