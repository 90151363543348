<template>
    <div class="columns mb-2">

        <div class="column is-4">
            <div class="control">
                <div>
                    <v-select
                        class="no-wrap-select"
                        v-model="item.name"
                        :options="items"
                        :clearable="false"
                        label="name"
                        :get-option-label="(option) => `${option.name}`"
                        placeholder="- wybierz produkt -"
                        @update:modelValue="itemChange($event)"
                        >
                        <template #no-options>
                            Brak wyników
                        </template>
                    </v-select>
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="quantity" step="0.01" class="input has-text-right" v-model="item.quantity">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="buy_price" step="0.01" class="input has-text-right" v-model="item.buy_price">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="sell_price" step="0.01" class="input has-text-right" v-model="item.sell_price" disabled>
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" step="0.01" class="input has-text-right" v-bind:value="gross_amount" disabled>
                </div>
            </div>
        </div>

        <div class="column is-1">
            <div class="control">
                <button class="button is-danger is-rounded is-outlined" style="width: 2.5em" @click="deleteItem"><strong>-</strong></button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import vSelect from 'vue-select'

export default {
    name: 'ItemForm',
    components: {
        vSelect
    },
    props: {
        initialItem: Object
    },
    data() {
        return {
            item: this.initialItem,
            items: []
        }
    },
    mounted() {
        this.getItems()
    },
    methods: {
        getItems() {
            axios
                .get('/api/v1/items/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.items.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        itemChange() {
            // Find the selected item from the items array
            const selectedItemId = this.item.name.id
            const selectedItem = this.items.find((item) => item.id === selectedItemId)

            // Update the buy_price field with the selected item's buy_price
            if (selectedItem) {
                this.item.buy_price = selectedItem.buy_price
                this.item.sell_price = selectedItem.sell_price
                this.item.item_code = selectedItem.item_code
                this.item.source_id = selectedItemId
            }
        },
        deleteItem() {
            // Emit an event to notify the parent component about the deletion
            this.$emit('deleteItem', this.item.item_num)
        },
    },
    computed: {
        gross_amount() {
            const buy_price = this.item.buy_price
            const quantity = this.item.quantity

            if (this.item.quantity === '') {
                this.item.gross_amount = 0
            } else {
                this.item.gross_amount = buy_price * quantity
            }

            this.item.gross_amount = Number(this.item.gross_amount.toFixed(2))

            this.$emit('updatePrice', this.item)

            return this.item.gross_amount.toFixed(2)
        }
    }
}
</script>