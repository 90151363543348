<template>
    <div class="page-clients">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/clients" aria-current="true">Kontrahenci</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Kontrahenci</h1>
                <button class="col-2 button is-success is-light is-outlined" @click="showNewClientModal = true">+ Nowy kontrahent</button>
            </div>
        
            <!-- Modal components -->
            <NewClientModal v-if="showNewClientModal" @submit="pageReload" @close="showNewClientModal = false"/>

            <div class="column is-4">
                <input type="text" class="input is-info" v-model="searchQuery" placeholder="Szukaj...">
            </div>

            <div class="column is-12 table-container">
                <table class="table is-fullwidth">
                    <thead>
                        <tr>
                            <th>Nazwisko i Imię</th>
                            <th>Nr dokumentu</th>
                            <!-- <th>Email</th> -->
                            <th>Adres</th>
                            <!-- <th>Kod pocztowy</th>
                            <th>Miejscowość</th> -->
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="client in filteredClients"
                            v-bind:key="client.id"
                        >
                            <td><router-link :to="{ name: 'Client', params: { id: client.id }}">{{ client.name }}</router-link></td>
                            <td>{{ client.doc_number }}</td>
                            <!-- <td>{{ client.email }}</td> -->
                            <td>{{ client.address1 }}</td>
                            <!-- <td>{{ client.zipcode }}</td>
                            <td>{{ client.place }}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import NewClientModal from '@/components/NewClientModal.vue'

export default {
    name: 'Clients',
    components: {
        NewClientModal
    },
    data() {
        return {
            clients: [],
            showNewClientModal: false,
            searchQuery: '',
        }
    },
    mounted() {
        this.getClients()
    },
    methods: {
        getClients() {
            axios
                .get('/api/v1/clients/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.clients.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        pageReload() {
            window.location.reload(true)
        },
    },
    computed: {
        filteredClients() {
            const query = this.searchQuery.toLowerCase();
            return this.clients.filter(client => {
                return (
                client.name.toLowerCase().includes(query) ||
                client.doc_number.toLowerCase().includes(query)
                );
            });
        },
    },
}
</script>