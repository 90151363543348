import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.validateStatus = function (status) {
	return status >= 200 && status < 600; // Consider all status codes as successful
  }
axios.defaults.baseURL = 'https://apizlom.dapps.com.pl'

const format = (date) => {
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	return `Selected date is ${day}/${month}/${year}`;
}

createApp(App).use(store).use(router, axios).mount('#app')
