<template>
    <div class="page-receipts">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/receipts" aria-current="true">Kwity</router-link></li>
            </ul>
        </nav>

        <!-- Modal components -->
        <PrintReceiptsModal v-if="showPrintReceiptsModal" @close="showPrintReceiptsModal = false"/>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Kwity</h1>
            </div>

            <div class="column is-12 mb-2">
                <router-link to="/dashboard/receipts/add-receipt" class="button is-success is-light is-outlined mr-2">Nowy kwit</router-link>
                <button class="col-2 button is-info is-light is-outlined" @click="showPrintReceiptsModal = true">Zestawienie księgowe</button>
            </div>

            <div class="column is-4">
                <input type="text" class="input is-info" v-model="searchQuery" placeholder="Szukaj...">
            </div>

            <div class="column is-10-widescreen is-12-desktop table-container">
                <table class="table is-fullwidth">
                    <thead>
                    <tr>
                        <th style="width: 300px;">Numer</th>
                        <th style="width: 200px;">Data</th>
                        <th style="width: 200px;">Kontrahent</th>
                        <th style="width: 200px;">Nr dokumentu</th>
                        <th style="width: 120px;">Wartość</th>
                    </tr>
                    </thead>
                    <tbody>
                        <template v-for="(receipt, index) in filteredReceipts" :key="receipt.id">
                            <tr @click="toggleExpand(receipt.id)" :class="{ 'is-expanded': receipt.expanded }">
                                <td>
                                    <router-link :to="{ name: 'Receipt', params: { id: receipt.id } }">
                                        {{ receipt.receipt_number }}
                                    </router-link>
                                </td>
                                <td>{{ formatDate(receipt.date) }}</td>
                                <td>{{ receipt.client_name }}</td>
                                <td>{{ receipt.client_doc_number }}</td>
                                <td class="has-text-right"><strong>{{ formatNumber(receipt.gross_amount) }} zł</strong></td>
                            </tr>
                            <tr v-if="receipt.expanded" class="expanded-row">
                                <td colspan="5">
                                    <!-- Add additional receipt details here -->
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style="width: 300px;">Produkt</th>
                                                <th style="width: 200px;">Kod produktu</th>
                                                <th style="width: 200px;">Ilość</th>
                                                <th style="width: 200px;">Cena</th>
                                                <th style="width: 120px;">Wartość</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in receipt.receipt_items">
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.item_code }}</td>
                                                <td>{{ item.quantity }} kg</td>
                                                <td>{{ item.buy_price }} zł</td>
                                                <td>{{ item.gross_amount }} zł</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import PrintReceiptsModal from '@/components/PrintReceiptsModal.vue'

export default {
    name: 'Receipts',
    components: {
        PrintReceiptsModal,
    },
    data() {
        return {
            receipts: [],
            showPrintReceiptsModal: false,
            searchQuery: '',
        }
    },
    mounted() {
        this.getReceipts()
    },
    methods: {
        getReceipts() {
            axios
                .get('/api/v1/receipts/')
                .then(response => {
                    this.receipts = response.data.map(receipt => ({
                        ...receipt,
                        expanded: false // Add expanded property
                    }));
                    console.log(this.receipts)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        toggleExpand(receiptId) {
            const receipt = this.receipts.find(r => r.id === receiptId);
            if (receipt) {
                receipt.expanded = !receipt.expanded;
            }
        },
        formatDate(date) {
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        },
        formatNumber(number) {
            // Convert number to string and split it into integer and decimal parts
            const parts = Number(number).toFixed(2).toString().split('.');
            const integerPart = parts[0];
            const decimalPart = parts[1];
            // Insert space every three digits in the integer part
            const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            // Concatenate formatted integer part with decimal part and return
            return `${formattedInteger},${decimalPart}`;
        },
    },
    computed: {
        filteredReceipts() {
            const query = this.searchQuery.toLowerCase();
            return this.receipts.filter(receipt => {
                return (
                receipt.receipt_number.toLowerCase().includes(query) ||
                receipt.client_name.toLowerCase().includes(query) ||
                this.formatDate(receipt.date).toLowerCase().includes(query) ||
                receipt.client_doc_number.toLowerCase().includes(query) ||
                receipt.receipt_items.some(item =>
                    item.name.toLowerCase().includes(query) || item.item_code.toLowerCase().includes(query)
                )
                );
            });
        },
    },
}

</script>

<style>
.expanded-row {
  background-color: #f9f9f9;
  font-size: 0.8rem;
}
.is-expanded {
  background-color: #e6f7ff;
}
</style>