<template>
    <div class="modal is-active">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Usuń produkt</p>
            <button class="delete" aria-label="close" @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <!-- Your form for adding a new category goes here -->
          <!-- Example form -->
          <form @submit.prevent="submitForm">
            <div class="field">
                <p>Czy na pewno chcesz usunąć produkt <strong>{{ item.name }}</strong>?</p>
                <hr>
            </div>
            <button type="button" class="button is-success is-light is-outlined mr-4" @click="submitForm">Usuń</button>
            <button type="button" class="button is-danger is-light is-outlined" @click="cancelForm">Anuluj</button>
          </form>
        </section>
      </div>
    </div>
</template>
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
	props: {
		itemID: Number
	},
    data() {
        return {
            item: {},
        };
    },
    mounted() {
      	this.getItem()
    },
    methods: {
        getItem() {
            axios
                .get(`/api/v1/items/${this.itemID}`)
                .then(response => {
                    this.item = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        submitForm() {
            axios
                .delete(`/api/v1/items/${this.itemID}/`)
                .then(response => {
                    toast({
                        message: 'Produkt został usunięty',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
					this.$emit('submit', this.item)
					this.$emit('close')
                })
                .catch(error => {
                    console.log(error.response)
                })
        },
		cancelForm() {
			this.$emit('close')
		}
    }
};
</script>

<style>
    .modal-card-body {
      	border-bottom-left-radius: 6px;
      	border-bottom-right-radius: 6px;
    }
</style>