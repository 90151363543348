<template>
    <div class="page-items-stats">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/items">Produkty</router-link></li>
                <li class="is-active"><router-link to="/dashboard/items/stats" aria-current="true">Statystyki produktów</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Statystyki produktów</h1>
            </div>
        </div>
             
        <div class="columns">
            <div class="column is-6-desktop is-12-tablet">
                <div class="columns">
                    <div class="column is-3">
                        <label class="label">Rodzaj zakresu</label>
                        <div class="select">
                            <select name="range_type" v-model="chosenType" @change="changeRangeType">
                                <option v-for="choice in typeChoices" v-bind:value="{ id: choice.id, name: choice.name }">
                                    {{ choice.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="column is-3-desktop is-6-mobile" v-if="chosenType.name === 'dzień'">
                        <div class="field">
                            <label class="label">Data:</label>

                            <VueDatePicker v-model="dateFrom" :enable-time-picker="false" :format="format" auto-apply @closed="dateChangeDay"/>
                        </div>
                    </div>

                    <div class="column is-3-desktop is-6-mobile" v-if="chosenType.name === 'miesiąc'">
                        <div class="field">
                            <label class="label">Miesiąc:</label>

                            <div class="select">
                                <select name="months_range" v-model="chosenMonth" @change="dateChangeMonth">
                                    <option v-for="msc in availableMonths" :key="msc.value" :value="msc.value">{{ msc.display }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="column is-3" v-if="chosenType.name === 'rok'">
                        <div class="field">
                            <label class="label">Rok:</label>

                            <div class="select">
                                <select name="years_range" v-model="chosenYear" @change="dateChangeYear">
                                    <option v-for="year in availableYears" :key="year.value" :value="year.value">{{ year.display }}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="column is-3-mobile" v-if="chosenType.name === 'inny'">
                        <div class="field">
                            <label class="label">Zakres od:</label>
                            <VueDatePicker v-model="dateFrom" :enable-time-picker="false" :format="format" auto-apply @closed="dateChangeRange"/>
                        </div>
                    </div>

                    <div class="column is-3-mobile" v-if="chosenType.name === 'inny'">
                        <div class="field">
                            <label class="label">Zakres do:</label>
                            <VueDatePicker v-model="dateTo" :enable-time-picker="false" :format="format" auto-apply @closed="dateChangeRange"/>
                        </div>
                    </div>

                    <div class="column is-3">
                        <label class="label">Kod odpadu</label>
                        <div class="select">
                            <select name="item_code" v-model="filter">
                                <option value="">wszystkie</option>
                                <option v-for="item in itemCodes" :key="item" :value="item">{{ item }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns is-multiline">
            <div class="column is-6-desktop is-12-tablet table-container">
                <table class="table is-striped is-hoverable">
                    <thead>
                        <tr>
                            <th @click="sortBy('item_code')"><div style="width: 100px;">Kod odpadu</div></th>
                            <th @click="sortBy('name')"><div style="width: 200px;">Produkt</div></th>
                            <th @click="sortBy('quantity')"><div class="has-text-right" style="width: 100px;">Ilość</div></th>
                            <th @click="sortBy('gross_amount')"><div class="has-text-right" style="width: 120px;">Wypłacono</div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in sortedItems"
                            v-bind:key="item.id"
                        >
                            <td>{{ item.item_code }}</td>
                            <td>{{ item.name }}</td>
                            <td class="has-text-right">{{ formatNumber(item.quantity) }} kg</td>
                            <td class="has-text-right">{{ formatNumber(item.gross_amount) }} zł</td>
                        </tr>
                        <tr>
                            <td><strong>Total</strong></td>
                            <td></td>
                            <td class="has-text-right"><strong>{{ formatNumber(totalQuantity) }} kg</strong></td>
                            <td class="has-text-right"><strong>{{ formatNumber(totalGrossAmount) }} zł</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="column is-6">
                <div class="box">
                    <h2 class="title is-4">Ilość produktów</h2>
                    <canvas ref="quantityChart" id="quantityChart"></canvas>
                </div>
                <div class="box mt-4">
                    <h2 class="title is-4">Wypłacone kwoty</h2>
                    <canvas ref="amountChart" id="amountChart"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import StatsChart from '@/components/StatsChart.vue'
import Chart from 'chart.js/auto'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: 'ItemStatistics',
    data() {
        return {
            dateFrom: new Date().toISOString().split('T')[0],
            dateTo: new Date().toISOString().split('T')[0],
            receipt_items: [],
            unique_items: [],
            sortByColumn: null,
            sortDirection: 'asc',
            filter: '',
            chosenType: {id: 1, name: 'dzień'},
            typeChoices: [
                {id: 1, name: 'dzień'},
                {id: 2, name: 'miesiąc'},
                {id: 3, name: 'rok'},
                {id: 4, name: 'inny'}
            ],
            availableMonths: [],
            chosenMonth: '',
            availableYears: [],
            chosenYear: '',
            quantityChart: null,
            amountChart: null,
        }
    },
    components: {
        StatsChart,
        VueDatePicker
    },
    mounted() {
        this.getReceiptItems(),
        this.getAvailableMonths(),
        this.getAvailableYears(),
        this.sortBy('item_code'),
        this.changeRangeType(),
        this.updateCharts()
    },
    watch: {
        sortedItems: {
            handler() {
            this.updateCharts();
            },
            deep: true
        }
    },
    computed: {
        sortedItems() {
            let filteredItems = [...this.unique_items];
            if (this.filter) {
                filteredItems = filteredItems.filter(item => item.item_code === this.filter);
            }
            if (this.sortByColumn) {
                filteredItems.sort((a, b) => {
                    const valA = a[this.sortByColumn];
                    const valB = b[this.sortByColumn];
                    if (valA < valB) return this.sortDirection === 'asc' ? -1 : 1;
                    if (valA > valB) return this.sortDirection === 'asc' ? 1 : -1;
                    return 0;
                });
            }
            return filteredItems;
        },
        itemCodes() {
            // Get unique item codes from unique_items
            return [...new Set(this.unique_items.map(item => item.item_code))].sort()
        },
        totalQuantity() {
            let totalQuantity = 0
            for (let i = 0;i < this.sortedItems.length; i++) {
                totalQuantity += this.sortedItems[i].quantity
            }
            return totalQuantity
        },
        totalGrossAmount() {
            let totalGrossAmount = 0
            for (let i = 0;i < this.sortedItems.length; i++) {
                totalGrossAmount += this.sortedItems[i].gross_amount
            }
            return totalGrossAmount
        }
    },
    methods: {
        getReceiptItems() {
            axios
                .get('/api/v1/receipts/')
                .then(response => {
                    this.receipt_items = []
                    const dateFrom = this.dateFrom.toISOString().split('T')[0]
                    const dateTo = this.dateTo.toISOString().split('T')[0]

                    for (let i = 0; i < response.data.length; i++) {
                        const receiptDate = response.data[i].date;
                        if (receiptDate >= dateFrom && receiptDate <= dateTo) {
                            
                            for (let x = 0; x < response.data[i].receipt_items.length; x++) {
                                this.receipt_items.push(response.data[i].receipt_items[x])
                            }
                        }
                    }
                    this.calculateItems()
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        getAvailableMonths() {
            axios
                .get('/api/v1/receipts/')
                .then(response => {
                const monthsSet = new Set()
                for (let i = 0; i < response.data.length; i++) {
                    const receiptDate = new Date(response.data[i].date)
                    // Create a string key using year and month
                    const monthKey = `${receiptDate.getFullYear()}-${receiptDate.getMonth() + 1}`
                    monthsSet.add(monthKey)
                }
                this.availableMonths = Array.from(monthsSet)
                    .map(monthKey => {
                    const [year, month] = monthKey.split('-').map(Number)
                    const date = new Date(year, month - 1) // month is 0-indexed in Date constructor
                    return {
                        display: date.toLocaleDateString('pl-PL', { year: 'numeric', month: 'long' }),
                        value: date.toISOString()
                    }
                    })
                    .sort((a, b) => new Date(a.value) - new Date(b.value))

                // Set the latest month as the initial selected value
                if (this.availableMonths.length > 0) {
                    this.chosenMonth = this.availableMonths[this.availableMonths.length - 1].value
                }
                })
                .catch(error => {
                console.log(JSON.stringify(error))
                })
            },
        getAvailableYears() {
            axios
                .get('/api/v1/receipts/')
                .then(response => {
                    const yearsSet = new Set()

                    for (let i = 0; i < response.data.length; i++) {
                        const receiptDate = response.data[i].date
                        const date = new Date(receiptDate)
                        const year = date.getFullYear();

                        // Create a new date object for the first day of the month
                        const firstDayOfYear = new Date(year, 1, 2)
                        yearsSet.add(firstDayOfYear.toISOString().split('T')[0])
                    }

                    this.availableYears = Array.from(yearsSet).sort((a, b) => new Date(a) - new Date(b)).map(dateStr => {
                        const date = new Date(dateStr);
                        return {
                        display: date.toLocaleDateString('pl-PL', { year: 'numeric' }),
                        value: dateStr
                        }
                    })

                    // Set the latest month as the initial selected value
                    if (this.availableYears.length > 0) {
                        this.chosenYear = this.availableYears[this.availableYears.length - 1].value
                    }

                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        calculateItems() {
            // Initialize an object to store unique items
            const uniqueItemsMap = {};

            this.receipt_items.forEach(item => {
                const { source_id, name, item_code, quantity, gross_amount } = item;
                
                // Convert gross_amount to a number
                const grossAmountNumber = parseFloat(gross_amount);
                
                if (uniqueItemsMap[source_id]) {
                    // If item exists, update quantity and gross_value
                    uniqueItemsMap[source_id].name = name;
                    uniqueItemsMap[source_id].item_code = item_code;
                    uniqueItemsMap[source_id].quantity += quantity;
                    uniqueItemsMap[source_id].gross_amount += grossAmountNumber;
                } else {
                    // If item does not exist, create new entry
                    uniqueItemsMap[source_id] = {
                        source_id,
                        name,
                        item_code,
                        quantity,
                        gross_amount: grossAmountNumber
                    };
                }
            });
            
            // Convert object to array of unique items
            this.unique_items = Object.values(uniqueItemsMap);
        },
        async dateChangeDay() {
            this.dateTo = this.dateFrom
            await this.getReceiptItems()
            await this.calculateItems
        },
        async dateChangeMonth() {
            this.dateFrom = new Date(this.chosenMonth)
            this.dateTo = new Date(this.dateFrom.getFullYear(), this.dateFrom.getMonth()+1, 0)
            await this.getReceiptItems()
            await this.calculateItems
        },
        async dateChangeYear() {
            this.dateFrom = new Date(this.chosenYear)
            this.dateTo = new Date(this.dateFrom.getFullYear(), 11, 30)
            await this.getReceiptItems()
            await this.calculateItems
        },
        async dateChangeRange() {
            await this.getReceiptItems()
            await this.calculateItems
        },
        setWeekRange() {
            const currentDate = new Date();
            const firstDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()+1));
            const lastDayOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 7));
            
            // Format dates as YYYY-MM-DD strings
            const formattedFirstDay = firstDayOfWeek.toISOString().split('T')[0];
            const formattedLastDay = lastDayOfWeek.toISOString().split('T')[0];
            
            // Set the data properties
            this.dateFrom = formattedFirstDay;
            this.dateTo = formattedLastDay;
        },
        sortBy(column) {
            // Toggle sort direction if the same column header is clicked
            if (this.sortByColumn === column) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
            } else {
                // Otherwise, set the column to sort by and reset sort direction
                this.sortByColumn = column;
                this.sortDirection = 'asc';
            }
        },
        formatNumber(number) {
            // Convert number to string and split it into integer and decimal parts
            const parts = number.toFixed(2).toString().split('.');
            const integerPart = parts[0];
            const decimalPart = parts[1];
            // Insert space every three digits in the integer part
            const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            // Concatenate formatted integer part with decimal part and return
            return `${formattedInteger},${decimalPart}`;
        },
        format(date) {
            let day = date.getDate()
            let month = date.getMonth() + 1
            let year = date.getFullYear()

            return `${day}/${month}/${year}`
        },
        changeRangeType() {
            if (this.chosenType.name === "dzień") {
                this.dateFrom = new Date()
                this.dateTo = new Date()
            } else if (this.chosenType.name === "miesiąc") {
                this.dateFrom = new Date(this.chosenMonth)
                this.dateTo = new Date(this.dateFrom.getFullYear(), this.dateFrom.getMonth()+1, 0)
            } else if (this.chosenType.name === "rok") {
                this.dateFrom = new Date(this.chosenYear)
                this.dateTo = new Date(this.dateFrom.getFullYear(), 11, 30)
            } else {
                this.dateFrom = new Date(this.availableMonths[0].value)
                this.dateTo = new Date(new Date(this.availableMonths[this.availableMonths.length - 1].value).getFullYear(), new Date(this.availableMonths[this.availableMonths.length - 1].value).getMonth()+1, 0)
            }
            
            this.getReceiptItems()
        },
        createQuantityChart() {
            const ctx = document.getElementById('quantityChart');
            
            if (this.quantityChart) {
                this.quantityChart.destroy();
            }
            
            this.quantityChart = new Chart(ctx, {
                type: 'pie',
                data: {
                labels: this.sortedItems.map(item => item.name),
                datasets: [{
                    label: 'Ilość [kg]',
                    data: this.sortedItems.map(item => item.quantity),
                    backgroundColor: this.generateColors(this.sortedItems.length),
                    borderColor: 'rgba(255, 255, 255, 0.9)',
                    borderWidth: 1
                }]
                },
                options: {
                responsive: true,
                plugins: {
                    legend: {
                    position: 'bottom',
                    }
                }
                }
            });
        },
        createAmountChart() {
            const ctx = document.getElementById('amountChart');
            
            if (this.amountChart) {
                this.amountChart.destroy();
            }
            
            this.amountChart = new Chart(ctx, {
                type: 'bar',
                data: {
                labels: this.sortedItems.map(item => item.name),
                datasets: [{
                    label: 'Wypłacono (zł)',
                    data: this.sortedItems.map(item => item.gross_amount),
                    backgroundColor: this.generateColors(this.sortedItems.length)
                }]
                },
                options: {
                responsive: true,
                plugins: {
                    legend: {
                    display: false
                    }
                }
                }
            });
        },
        generateColors(count) {
            const colors = [
                'rgba(171, 196, 174, 0.8)',  // Muted green
                'rgba(176, 196, 222, 0.8)',  // Light steel blue
                'rgba(188, 143, 143, 0.8)',  // Rosy brown
                'rgba(204, 204, 153, 0.8)',  // Dark khaki
                'rgba(160, 160, 160, 0.8)',  // Gray
                'rgba(222, 184, 135, 0.8)',  // Burlywood
                'rgba(175, 238, 238, 0.8)',  // Pale turquoise
                'rgba(216, 191, 216, 0.8)',  // Thistle
                'rgba(244, 164, 96, 0.8)',   // Sandy brown
                'rgba(176, 224, 230, 0.8)',  // Powder blue
            ];

            // If we need more colors than in our predefined list, generate them
            if (count > colors.length) {
                for (let i = colors.length; i < count; i++) {
                const hue = (i * 137.508) % 360; // Use golden angle approximation
                colors.push(`hsla(${hue}, 40%, 70%, 0.8)`);
                }
            }

            return colors.slice(0, count);
        },
        updateCharts() {
            this.$nextTick(() => {
                if (this.$refs.quantityChart && this.$refs.amountChart) {
                this.createQuantityChart();
                this.createAmountChart();
                }
            });
        },
    }
}
</script>