<template>
    <div class="page-client">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/clients">Kontrahenci</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'Client', params: { id: client.id }}" aria-current="true">{{ client.name }}</router-link></li>
            </ul>
        </nav>

        <EditClientModal v-if="showEditClientModal" @submit="pageReload" @close="showEditClientModal = false" :clientID="selectedClientID"/>
        <DeleteClientModal v-if="showDeleteClientModal" @submit="deleteClient" @close="showDeleteClientModal = false" :clientID="selectedClientID"/>
        
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">{{ client.name }}</h1>

                <button class="button is-warning is-outlined is-light mt-4 mr-4" @click="openEditClientModal(client.id)">Edytuj</button>
                <button class="button is-danger is-outlined is-light mt-4 mr-4" @click="openDeleteClientModal(client.id)">Usuń</button>
            </div>

            <div class="column is-4">
                <div class="box">
                    <h2 class="title is-4">Dane kontaktowe</h2>

                    <p><strong>{{ client.name }}</strong></p>
                    
                    <p v-if="client.address1">{{ client.address1 }}</p>
                    <p v-if="client.zipcode || client.place">{{ client.zipcode }} {{ client.place }}</p>
                </div>
            </div>
            <div class="column is-8">
                <div class="box">
                    <h1 class="title is-4">Podsumowanie</h1>
                    <div class="columns">
                        <div class="column is-4 level-item has-text-centered">
                            <div>
                            <p class="heading">Wypłacono</p>
                            <p class="title">{{ gross_total_value }} zł</p>
                            </div>
                        </div>

                        <div class="column is-4 level-item has-text-centered">
                            <div>
                            <p class="heading">Odebrano</p>
                            <p class="title">{{ total_quantity }} kg</p>
                            </div>
                        </div>

                        <div class="column is-4 level-item has-text-centered">
                            <div>
                            <p class="heading">Ilość transakcji</p>
                            <p class="title">{{ this.client.receipts.length }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-12" v-if="client.receipts">
                <div class="box">
                    <h2 class="title is-4">Kwity</h2>
                    <div class="table-container">
                        <table class="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th>Numer</th>
                                    <th class="has-text-centered">Data</th>
                                    <th style="width: 120px;">Wartość</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="receipt in client.receipts"
                                    v-bind:key="receipt.id"
                                >
                                    <td>{{ receipt.receipt_number }}</td>
                                    <td class="has-text-centered">{{ receipt.date }}</td>
                                    <td class="has-text-right">{{ receipt.gross_amount }} zł</td>
                                    <td class="has-text-right">
                                        <router-link :to="{ name: 'Receipt', params: { id: receipt.id } }">Szczegóły</router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import EditClientModal from '@/components/EditClientModal.vue'
import DeleteClientModal from '@/components/DeleteClientModal.vue'

export default {
    name: 'Client',
    components: {
        EditClientModal,
        DeleteClientModal
    },
    data () {
        return {
            client: {
                receipts: []
            },
            showEditClientModal: false,
            showDeleteClientModal: false,
            selectedClientID: null,
            gross_total_value: 0,
            total_quantity: 0
        }
    },
    mounted() {
        this.getClient()
    },
    methods: {
        getClient() {
            const clientID = this.$route.params.id

            axios
                .get(`/api/v1/clients/${clientID}`)
                .then(response => {
                    this.client = response.data

                    let gross_total_value = 0
                    let total_quantity = 0

                    for (let i = 0; i < this.client.receipts.length; i++) {
                        gross_total_value += Number(this.client.receipts[i].gross_amount)
                        total_quantity += Number(this.client.receipts[i].total_quantity)
                    }
                    this.gross_total_value = gross_total_value.toFixed(2)
                    this.total_quantity = total_quantity.toFixed(2)
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        openEditClientModal(clientID) {
            this.selectedClientID = clientID
            this.showEditClientModal = true
        },
        openDeleteClientModal(clientID) {
            this.selectedClientID = clientID
            this.showDeleteClientModal = true
        },
        pageReload() {
            window.location.reload(true)
        },
        deleteClient() {
            this.$router.push('/dashboard/clients')
        }
    },
    computed: {
    }
}
</script>