<template>
    <div class="page-items">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li class="is-active"><router-link to="/dashboard/items" aria-current="true">Produkty</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Produkty</h1>

                <div class="mb-3">
                    <router-link to="/dashboard/items/stats" class="button is-info is-light is-outlined mr-4 mb-2">Statystyki</router-link>
                    <button class="col-2 button is-success is-light is-outlined mr-4 mb-2" @click="submitForm">Zapisz zmiany cen</button>
                    <button class="col-2 button is-warning is-light is-outlined mr-4 mb-2" @click="toggleEdit">Tryb edycji</button>
                </div>
                <div>
                    <button class="col-2 button is-success is-light is-outlined mr-4 mb-2" @click="showNewCategoryModal = true">+ Nowa kategoria</button>
                    <button class="col-2 button is-success is-light is-outlined mr-4 mb-2" @click="showNewItemModal = true">+ Nowy produkt</button>
                </div>
            </div>
        </div>

        <!-- Modal components -->
        <NewCategoryModal v-if="showNewCategoryModal" @submit="pageReload" @close="showNewCategoryModal = false"/>
        <EditCategoryModal v-if="showEditCategoryModal" @submit="pageReload" @close="showEditCategoryModal = false" :categoryID="selectedCategoryID"/>
        <DeleteCategoryModal v-if="showDeleteCategoryModal" @submit="pageReload" @close="showDeleteCategoryModal = false" :categoryID="selectedCategoryID"/>

        <NewItemModal v-if="showNewItemModal" @submit="pageReload" @close="showNewItemModal = false"/>
        <EditItemModal v-if="showEditItemModal" @submit="pageReload" @close="showEditItemModal = false" :itemID="selectedItemID"/>
        <DeleteItemModal v-if="showDeleteItemModal" @submit="pageReload" @close="showDeleteItemModal = false" :itemID="selectedItemID"/>

        <div>
            <div class="columns is-multiline">
                <div class="column is-6">
                    <div class="columns is-multiline" v-for="category in categories_left" v-bind:key="category.id">
                        <div class="column is-12">
                            <div class="box">
                                <h3 class="title is-4 mb-3">{{ category.name }}</h3>
                                <button v-if="editMode" class="button is-warning is-outlined is-light is-small mb-3" @click="openEditCategoryModal(category.id)">Edytuj</button>
                                <button v-if="editMode" class="button is-danger is-outlined is-light is-small ml-4 mb-3" @click="openDeleteCategoryModal(category.id)">Usuń</button>

                                <div class="table-container">
                                    <table class="table is-hoverable">
                                        <thead>
                                            <tr>
                                                <th><div style="width: 150px;">Produkt</div></th>
                                                <th><div style="width: 90px;">Kod odpadu </div></th>
                                                <th><div style="width: 90px;">Cena skupu</div></th>
                                                <th><div style="width: 90px;">Cena sprzedaży</div></th>
                                                <th v-if="editMode"></th>
                                                <th v-if="editMode"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr
                                                v-for="item in items"
                                                v-bind:key="item.id"
                                            >
                                                <td v-if="item.category === category.id">{{ item.name }}</td>
                                                <td v-if="item.category === category.id"><div style="width: 90px;" class="has-text-centered">{{ item.item_code }}</div></td>
                                                <td v-if="item.category === category.id"><input type="number" step="0.01" class="input is-small has-text-right" style="width: 90px;" size="3" v-model="item.buy_price"></td>
                                                <td v-if="item.category === category.id"><input type="number" step="0.01" class="input is-small has-text-right" style="width: 90px;" size="4" v-model="item.sell_price"></td>
                                                <td v-if="item.category === category.id && editMode"><button class="button is-warning is-outlined is-light is-small" @click="openEditItemModal(item.id)">Edytuj</button></td>
                                                <td v-if="item.category === category.id && editMode"><button class="button is-danger is-outlined is-light is-small" @click="openDeleteItemModal(item.id)">Usuń</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-6">
                    <div class="columns is-multiline" v-for="category in categories_right" v-bind:key="category.id">
                        <div class="column is-12">
                            <div class="box">
                                <h3 class="title is-4 mb-3">{{ category.name }}</h3>
                                <button v-if="editMode" class="button is-warning is-outlined is-light is-small mb-3" @click="openEditCategoryModal(category.id)">Edytuj</button>
                                <button v-if="editMode" class="button is-danger is-outlined is-light is-small ml-4 mb-3" @click="openDeleteCategoryModal(category.id)">Usuń</button>

                                <div class="table-container">
                                    <table class="table is-hoverable">
                                        <thead>
                                            <tr>
                                                <th><div style="width: 150px;">Produkt</div></th>
                                                <th><div style="width: 90px;">Kod odpadu </div></th>
                                                <th><div style="width: 90px;">Cena skupu</div></th>
                                                <th><div style="width: 90px;">Cena sprzedaży</div></th>
                                                <th v-if="editMode"></th>
                                                <th v-if="editMode"></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr
                                                v-for="item in items"
                                                v-bind:key="item.id"
                                            >
                                                <td v-if="item.category === category.id">{{ item.name }}</td>
                                                <td v-if="item.category === category.id"><div style="width: 90px;" class="has-text-centered">{{ item.item_code }}</div></td>
                                                <td v-if="item.category === category.id"><input type="number" step="0.01" class="input is-small has-text-right" style="width: 90px;" size="3" v-model="item.buy_price"></td>
                                                <td v-if="item.category === category.id"><input type="number" step="0.01" class="input is-small has-text-right" style="width: 90px;" size="4" v-model="item.sell_price"></td>
                                                <td v-if="item.category === category.id && editMode"><button class="button is-warning is-outlined is-light is-small" @click="openEditItemModal(item.id)">Edytuj</button></td>
                                                <td v-if="item.category === category.id && editMode"><button class="button is-danger is-outlined is-light is-small" @click="openDeleteItemModal(item.id)">Usuń</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import NewCategoryModal from '@/components/NewCategoryModal.vue'
import EditCategoryModal from '@/components/EditCategoryModal.vue'
import DeleteCategoryModal from '@/components/DeleteCategoryModal.vue'

import NewItemModal from '@/components/NewItemModal.vue'
import EditItemModal from '@/components/EditItemModal.vue'
import DeleteItemModal from '@/components/DeleteItemModal.vue'

export default {
    name: 'Items',
    components: {
        NewCategoryModal,
        EditCategoryModal,
        DeleteCategoryModal,
        NewItemModal,
        EditItemModal,
        DeleteItemModal
    },
    data() {
        return {
            categories_left: [],
            categories_right: [],
            items: [],
            editMode: false,
            showNewCategoryModal: false,
            showEditCategoryModal: false,
            showDeleteCategoryModal: false,
            selectedCategoryID: null,
            showNewItemModal: false,
            showEditItemModal: false,
            showDeleteItemModal: false,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            await axios
                .get('/api/v1/items/')
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        this.items.push(response.data[i])
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })

            axios
                .get('/api/v1/categories/')
                .then(response => {
                    let left_count = 0
                    let right_count = 0
                    for (let i = 0; i < response.data.length; i++) {
                        if (left_count <= right_count) {
                            this.categories_left.push(response.data[i])
                            left_count += this.items.filter(item => item.category === response.data[i].id).length
                        } else {
                            this.categories_right.push(response.data[i])
                            right_count += this.items.filter(item => item.category === response.data[i].id).length
                        }
                    }
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        toggleEdit() {
            this.editMode = !this.editMode
        },
        submitForm() {
            // Prepare data for updating buy_price and sell_price
            const updatedItems = this.items
                .filter(item => item.buy_price !== undefined || item.sell_price !== undefined)
                .map(
                    item => ({
                        id: item.id,
                        buy_price: item.buy_price,
                        sell_price: item.sell_price
                    })
                )

            // Update backend using Axios
            updatedItems.forEach(item => {
                axios
                    .patch(`/api/v1/items/${item.id}/`, {
                        buy_price: item.buy_price,
                        sell_price: item.sell_price
                    })
                    .then(response => {
                        
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            },
                toast({
                    message: 'Zapisano nowe ceny produktów',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                })
            )
        },
        openEditCategoryModal(categoryID) {
            this.selectedCategoryID = categoryID
            this.showEditCategoryModal = true
        },
        openDeleteCategoryModal(categoryID) {
            this.selectedCategoryID = categoryID
            this.showDeleteCategoryModal = true
        },
        openEditItemModal(itemID) {
            this.selectedItemID = itemID
            this.showEditItemModal = true
        },
        openDeleteItemModal(itemID) {
            this.selectedItemID = itemID
            this.showDeleteItemModal = true
        },
        pageReload() {
            window.location.reload(true)
        },
    }
}
</script>