<template>
    <div class="page-change-password">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Zmiana hasła</h1>

                <form @submit.prevent="changePassword">
                    <div class="field">
                        <label>Obecne hasło</label>
                        <div class="control">
                            <input type="password" name="current_password" class="input" v-model="currentPassword" required>
                        </div>
                    </div>
	
                    <div class="field">
                        <label>Nowe hasło</label>
                        <input class="input" :class='{valid:passwordValidation.valid}' :type="passwordVisible ? 'text' : 'password'" v-model="new_password" required>
                        <!-- <button class="visibility" tabindex='-1' @click='togglePasswordVisibility' :arial-label='passwordVisible ? "Hide password" : "Show password"'>
                            <i class="material-icons">{{ passwordVisible ? "visibility" : "visibility_off" }}</i>
                        </button> -->
                    </div>

                    <div class="field">
                        <label>Powtórz nowe hasło</label>
                        <div class="control">
                            <input type="password" name="re_new_password" class="input" v-model="re_new_password" required>
                        </div>
                    </div>

                    <div class="field" v-if='passwordsFilled && newPasswordsFilled && !samePasswords && !notSameNewPasswords && passwordValidation.valid'>
                        <div class="control">
                            <button type="submit" class="button is-success">Zmień hasło</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="columns">
            <div class="column is-6 is-offset-3 has-text-centered">
                <transition name="hint" appear>
                    <div v-if='passwordValidation.errors.length > 0' class='hints'>
                        <p><strong>Wskazówki</strong></p>
                        <p v-for='error in passwordValidation.errors'>{{error}}</p>
                    </div>
                </transition>
                
                <div class="matches" v-if='samePasswords'>
                    <p>Nowe hasło nie może być takie jak dotychczasowe.</p>
                </div>
                <div class="matches" v-if='notSameNewPasswords'>
                    <p>Nowe hasła nie są takie same.</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
  
    export default {
        data() {
        return {
            currentPassword: '',
            new_password: '',
            re_new_password: '',
            rules: [
                { message:'Wymagana jest minimum jedna mała litera.', regex:/[a-z]+/ },
                { message:"Wymagana jest minimum jedna wielka litera.",  regex:/[A-Z]+/ },
                { message:"Minimum 8 znaków.", regex:/.{8,}/ },
                { message:"Wymagana jest minimum jedna cyfra", regex:/[0-9]+/ }
            ],
            passwordVisible:false
        };
        },
        methods: {
            async changePassword() {
                try {
                    const response = await axios.post('/api/v1/users/set_password/', {
                        current_password: this.currentPassword,
                        new_password: this.new_password,
                        re_new_password: this.re_new_password
                    });

                    if (response.status >= 200 && response.status < 300) {
                        toast({
                            message: 'Hasło zostało zmienione',
                            type: 'is-success',
                            dismissible: true,
                            pauseOnHover: true,
                            duration: 2000,
                            position: 'bottom-right',
                        });
                        this.$router.push('/dashboard');
                    } else {
                        throw new Error('Invalid password.');
                    }
                } catch (error) {
                    console.error('Error:', error)
                    
                    let errorMessage = 'WWprowadzono niewłaściwe obecne hasło. Proszę spróbować ponownie.'
                    
                    toast({
                        message: errorMessage,
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    });
                }
            },
            togglePasswordVisibility () {
                this.passwordVisible = !this.passwordVisible
            }	
        },
        computed: {
            notSameNewPasswords () {
                if (this.newPasswordsFilled) {
                    return (this.new_password !== this.re_new_password)
                } else {
                    return false
                }
            },
            samePasswords () {
                if (this.passwordsFilled) {
                    return (this.currentPassword === this.new_password)
                } else {
                    return false
                }
            },
            passwordsFilled () {
                return (this.currentPassword !== '' && this.new_password !== '')
            },
            newPasswordsFilled () {
                return (this.new_password !== '' && this.re_new_password !== '')
            },
            passwordValidation () {
                let errors = []
                for (let condition of this.rules) {
                    if (!condition.regex.test(this.new_password)) {
                        errors.push(condition.message)
                    }
                }
                if (errors.length === 0) {
                    return { valid:true, errors }
                } else {
                    return { valid:false, errors }
                }
            }
        }
    }
</script>