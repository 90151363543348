<template>
    <div class="modal is-active">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Zestawienie ksiegowe</p>
          <button class="delete" aria-label="close" @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <!-- Your form for adding a new category goes here -->
          <!-- Example form -->
          <form @submit.prevent="submitForm">
            <div class="field">
                <label class="title is-4">Wybierz zakres</label>
                <div class="columns mt-2">
                    <div class="column is-6 field">
                        <label class="label">Zakres od:</label>

                        <div class="control">
                            <input type="date" name="receipt_date" class="input" v-model="dateFrom" @change="dateFromChange">
                        </div>
                    </div>
                    <div class="column is-6 field">
                        <label class="label">Zakres do:</label>

                        <div class="control">
                            <input type="date" name="receipt_date" class="input" v-model="dateTo" @change="dateToChange">
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="button is-success is-light is-outlined mr-4" @click="getPdf(dateFrom, dateTo)">Pobierz</button>
            <button type="button" class="button is-danger is-light is-outlined" @click="cancelForm">Anuluj</button>
          </form>
        </section>
      </div>
    </div>
</template>
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

const fileDownload = require('js-file-download')


export default {
    data() {
        return {
            dateFrom: '',
            dateTo: '',
        };
    },
    mounted() {
        this.setMonthRange()
    },
    methods: {
        async getPdf(dateFrom, dateTo) {
            if (this.dateFrom === undefined || this.dateTo === undefined) {
                toast({
                        message: 'Proszę wybrać właściwy zakres.',
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                    return
            }

            await axios
                .get(`/api/v1/generate_receipts/`, {
                        responseType: 'blob',
                        headers: {
                            'X-Date-From': dateFrom,
                            'X-Date-To': dateTo
                        }
                    })
                .then(res => {
                    fileDownload(res.data, `kwity_${this.dateFrom}_${this.dateTo}.pdf`)
					this.$emit('close')
                })
                .catch(err => {
                    console.log(err)})
        },
        cancelForm() {
          	this.$emit('close')
        },
        setMonthRange() {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
            
            // Format dates as YYYY-MM-DD strings
            const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];
            const formattedLastDay = lastDayOfMonth.toISOString().split('T')[0];
            
            // Set the data properties
            this.dateFrom = formattedFirstDay;
            this.dateTo = formattedLastDay;
        },
        dateFromChange() {
            if (this.dateFrom > this.dateTo) {
                this.dateTo = undefined
            }
        },
        dateToChange() {
            if (this.dateTo < this.dateFrom) {
                this.dateFrom = undefined
            }
        },
        async handleEnter(event) {
            if (event.key === 'Enter') {
                await this.getPdf(this.dateFrom, this.dateTo)
            }
        },
        handleEsc(event) {
            if (event.key === 'Escape') {
                this.cancelForm()
            }
        },
	}
}
</script>

<style>
    .modal-card-body {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
</style>