<template>
    <div class="modal is-active">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Nowa kategoria</p>
          <button class="delete" aria-label="close" @click="$emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <!-- Your form for adding a new category goes here -->
          <!-- Example form -->
          <form @submit.prevent="submitForm">
            <div class="field">
              <label class="label">Nazwa</label>
              <div class="control">
                <input class="input" type="text" v-model="category.name" required>
              </div>
            </div>
            <button type="button" class="button is-success is-light is-outlined mr-4" @click="submitForm">Zapisz</button>
            <button type="button" class="button is-danger is-light is-outlined" @click="cancelForm">Anuluj</button>
          </form>
        </section>
      </div>
    </div>
</template>
  
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    data() {
        return {
            category: {}
        };
    },
    methods: {
        submitForm() {
			// Validation for name
            if (this.category.name === undefined || !this.category.name.trim()) {
                toast({
                    message: 'Proszę wprowadzić nazwę kategorii.',
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                })
                return
            }

            axios
            .post("/api/v1/categories/", this.category)
            .then(response => {
                toast({
                    message: 'Dodano kategorię',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                })
            	this.$emit('close')
            	this.$emit('submit', this.category)
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        cancelForm() {
          	this.$emit('close')
        }
	}
}
</script>

<style>
    .modal-card-body {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    /* .modal-mask {
      position: fixed;
      z-index: 9998;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      transition: opacity 0.3s ease;
    }

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;
    }

    .modal-container {
      width: 300px;
      margin: 0px auto;
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }

    .modal-header h3 {
      margin-top: 0;
      color: #42b983;
    }

    .modal-body {
      margin: 20px 0;
    }

    .modal-default-button {
      float: right;
    }

    .modal-enter-from {
      opacity: 0;
    }

    .modal-leave-to {
      opacity: 0;
    }

    .modal-enter-from .modal-container,
    .modal-leave-to .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    } */
</style>