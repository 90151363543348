<template>
    <div class="page-login">
        <div class="columns">
            <div class="column is-4 is-offset-4">
                <h1 class="title">Logowanie</h1>

                <form @submit.prevent="submitForm">
                    <div class="field">
                        <label>Nazwa użytkownika</label>
                        <div class="control">
                            <input type="email" name="username" class="input" v-model="username">
                        </div>
                    </div>

                    <div class="field">
                        <label>Hasło</label>
                        <div class="control">
                            <input type="password" name="password" class="input" v-model="password">
                        </div>
                    </div>

                    <div class="notification is-danger" v-if="errors.length">
                        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                    </div>

                    <div class="field">
                        <div class="control">
                            <button class="button is-success">Logowanie</button>
                        </div>
                    </div>
                </form>

                <hr>
                <!-- <router-link to="/sign-up">Kliknij tutaj</router-link> aby się zarejestrować -->
            </div>
        </div>
        <div class="columns">
            <div class="column is-6 is-offset-3 has-text-centered">
                <p>ZłomApp jest na razie w fazie testów, więc dostęp ma tylko niewielkie grono użytkowników.</p>
                <p>Jeśli jesteś zainteresowany korzystaniem z platformy skontaktuj się z administratorem mailowo - kusmiderdaniel@gmail.com</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'LogIn',
    data () {
        return {
            username: '',
            password: '',
            errors: []
        }
    },
    methods: {
        async submitForm(e) {
            this.errors = [];  // Clear previous errors
            axios.defaults.headers.common["Authorization"] = ""
            localStorage.removeItem('token')

            const formData = {
                username: this.username,
                password: this.password
            }

            try {
                const response = await axios.post("/api/v1/token/login/", formData)

                if (response.status >= 200 && response.status < 300) {
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    localStorage.setItem("token", token)

                    // Fetch user data
                    const userResponse = await axios.get('/api/v1/users/me')
                    this.$store.commit('setUser', {'username': userResponse.data.username, 'id': userResponse.data.id})
                    localStorage.setItem('username', userResponse.data.username)
                    localStorage.setItem('userid', userResponse.data.id)

                    this.$router.push('/dashboard')
                } else {
                    throw new Error('Invalid credentials.')
                }
            } catch (error) {
                this.errors.push("Nieprawidłowa nazwa użytkownika lub hasło. Proszę spróbować ponownie.")
                console.error('Error:', error)
            }
        }
    }
}
</script>