<template>
    <div class="page-add-category">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/items">Produkty</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'EditCategory', params: { id: category.id }}" aria-current="true">Edytuj {{ category.name }}</router-link></li>
            </ul>
        </nav>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Edytuj kategorię - {{ category.name }}</h1>
            </div>

            <div class="column is-6">
                <div class="field">
                    <label>Nazwa</label>
                    
                    <div class="control">
                        <input type="text" name="name" class="input" v-model="category.name">
                    </div>
                </div>
            </div>

            <div class="column is-12">
                <div class="field">
                    <div class="control">
                        <button class="button is-success mr-4" @click="submitForm">Zapisz</button>
                        <button class="button is-danger" @click="confirmCancel">Anuluj</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
    name: 'EditCategory',
    data() {
        return {
            category: {}
        }
    },
    mounted() {
        this.getCategory()
    },
    methods: {
        getCategory() {
            const categoryID = this.$route.params.id

            axios
                .get(`/api/v1/categories/${categoryID}`)
                .then(response => {
                    this.category = response.data
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        confirmCancel() {
            const confirmMessage = `Czy na pewno chcesz anluować wprowadzanie kategorii?`

            if (this.category.name != undefined) {
                if (window.confirm(confirmMessage)) {
                    this.$router.push('/dashboard/items')
                }
            } else {
                this.$router.push('/dashboard/items')
            }
        },
        submitForm() {
            const categoryID = this.$route.params.id
            const tempCategory = { ...this.category }

            axios
                .patch(`/api/v1/categories/${categoryID}/`, this.category)
                .then(response => {
                    toast({
                        message: 'Zmiany zostały zapisane',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })

                    this.$router.push('/dashboard/items')
                })
                .catch(error => {
                    console.log(error.response)
                })
        }
    }
}
</script>