<template>
    <div class="page-receipt">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/dashboard">Dashboard</router-link></li>
                <li><router-link to="/dashboard/receipts">Kwity</router-link></li>
                <li class="is-active"><router-link :to="{ name: 'Receipt', params: { id: receipt.id }}" aria-current="true">{{ receipt.receipt_number }}</router-link></li>
            </ul>
        </nav>

        <DeleteReceiptModal v-if="showDeleteReceiptModal" @submit="deleteReceipt" @close="showDeleteReceiptModal = false" :receiptID="selectedReceiptID"/>

        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">{{ receipt.receipt_number }}</h1>
                <h2 class="title is-5">Data: {{ formatDate(receipt.date) }}</h2>
                <div class="buttons">
                    <router-link :to="{ name: 'EditReceipt', params: { id: receipt.id }}" class="button is-warning is-light is-outlined mt-4">Edytuj</router-link>
                    <button class="button is-danger is-outlined is-light mt-4" @click="openDeleteReceiptModal(receipt.id)">Usuń</button>
                    <button @click="getPdf()" class="button is-dark is-light is-outlined mt-4">Pobierz PDF</button>
                </div>
            </div>

            <div class="column is-8 mb-4">
                <div class="box">
                    <h3 class="title is-4 mb-5">Kontrahent</h3>

                    <p><strong>{{ receipt.client_name }}</strong></p>
                    <p><strong>Nr dokumentu:</strong> {{ receipt.client_doc_number }}</p>
                    <p v-if="receipt.client_email"><strong>Email:</strong> {{ receipt.client_email }}</p>
                    <p><strong>Adres:</strong></p>
                    <p v-if="receipt.client_address1">{{ receipt.client_address1 }}</p>
                    <p v-if="receipt.client_zipcode || receipt.client_place">{{ receipt.client_zipcode }} {{ receipt.client_place }}</p>
                </div>
            </div>

            <div class="column is-4">
                <div class="box">
                    <h3 class="title is-4 mb-5">Podsumowanie</h3>

                    <div class="columns">
                        <div class="column is-6">
                            <p><strong>Do wypłaty</strong>: {{ receipt.gross_amount }} zł</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column is-8-widescreen is-12-desktop is-12-tablet mb-4">
                <div class="box">
                    <h3 class="title is-4 mb-5">Produkty</h3>
                    <div class="table-container">
                        <table class="table is-fullwidth">
                            <thead>
                                <tr>
                                    <th><div style="width: 120px;">Produkt</div></th>
                                    <th><div class="has-text-centered">Kod odpadu</div></th>
                                    <th><div style="width: 100px;" class="has-text-centered">Ilość</div></th>
                                    <th><div style="width: 120px;" class="has-text-centered">Cena skupu</div></th>
                                    <th><div style="width: 120px;" class="has-text-centered">Wartość</div></th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr 
                                    v-for="item in receipt.receipt_items"
                                    v-bind:key="item.id"
                                >
                                    <td>{{ item.name }}</td>
                                    <td class="has-text-centered">{{ item.item_code }}</td>
                                    <td style="width: 100px;" class="has-text-centered">{{ item.quantity }} kg</td>
                                    <td style="width: 120px;" class="has-text-centered">{{ item.buy_price }} zł</td>
                                    <td style="width: 120px;" class="has-text-centered">{{ getItemTotal(item) }} zł</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'

    import DeleteReceiptModal from '@/components/DeleteReceiptModal.vue'  

    const fileDownload = require('js-file-download')

    export default {
        name: 'Receipt',
        components: {
            DeleteReceiptModal
        },
        data() {
            return {
                receipt: {},
                showDeleteReceiptModal: false,
                selectedReceiptID: null,
            }
        },
        mounted() {
            this.getReceipt()
            console.log(this.receipt)
        },
        methods: {
            getReceipt() {
                const receiptID = this.$route.params.id

                axios
                    .get(`/api/v1/receipts/${receiptID}`)
                    .then(response => {
                        this.receipt = response.data
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            },
            formatDate(date) {
                const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
                return new Date(date).toLocaleDateString(undefined, options);
            },
            getPdf() {
                const receiptID = this.$route.params.id

                axios
                    .get(`/api/v1/receipts/${receiptID}/generate_pdf/`, {
                        responseType: 'blob',})
                    .then(res => {
                        fileDownload(res.data, `kwit_${this.receipt.receipt_number}.pdf`)})
                    .catch(err => {
                        console.log(err)})
            },
            getItemTotal(item) {
                const total = item.buy_price * item.quantity

                return parseFloat(total).toFixed(2)
            },
            openDeleteReceiptModal(receiptID) {
                this.selectedReceiptID = receiptID
                this.showDeleteReceiptModal = true
            },
            deleteReceipt() {
                this.$router.push('/dashboard/receipts')
            },
        }
    }
</script>