<template>
    <div class="columns mb-2">

        <div class="column is-4">
            <div class="control">
                <div>
                    <!-- <select name="name" v-model="item.name" @change="itemChange($event)">
                        <option value="">- wybierz produkt -</option>
                        <option 
                            v-for="item in items"
                            v-bind:key="item.id"
                            v-bind:value="item.name"
                        >
                            {{ item.name }}
                        </option>
                    </select> -->
                    <v-select
                        class="no-wrap-select"
                        v-model="item.name"
                        :options="items"
                        :clearable="false"
                        label="name"
                        placeholder="- wybierz produkt -"
                        @update:modelValue="itemChange($event)"
                        >
                        <template #no-options>
                            Brak wyników
                        </template>
                    </v-select>
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="quantity" step="0.01" class="input has-text-right" v-model="item.quantity" @change="itemUpdate">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="buy_price" step="0.01" class="input has-text-right" v-model="item.buy_price" @change="itemUpdate">
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" name="sell_price" step="0.01" class="input has-text-right" v-model="item.sell_price" disabled>
                </div>
            </div>
        </div>

        <div class="column is-2">
            <div class="field">
                <div class="control">
                    <input type="number" step="0.01" class="input has-text-right" v-bind:value="gross_amount" disabled>
                </div>
            </div>
        </div>

        <div class="column is-1">
            <div class="control">
                <button class="button is-danger is-rounded is-outlined" style="width: 2.5em" @click="deleteItem"><strong>-</strong></button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import vSelect from 'vue-select'

export default {
    name: 'EditItemForm',
    components: {
        vSelect
    },
    props: {
        initialItem: Object
    },
    data() {
        return {
            item: { ...this.initialItem },
            items: []
        }
    },
    mounted() {
        this.getItems()
        // this.initiate()
    },
    methods: {
        async getItems() {
            try {
                const response = await axios.get('/api/v1/items/');
                this.items = response.data;
                this.setInitialValue();
            } catch (error) {
                console.error(error);
            }
        },
        setInitialValue() {
            if (this.item.name) {
                const selectedItem = this.items.find(item => item.name === this.item.name);
                if (selectedItem) {
                    this.itemChange(selectedItem);
                }
            }
        },
        // getItems() {
        //     axios
        //         .get('/api/v1/items/')
        //         .then(response => {
        //             for (let i = 0; i < response.data.length; i++) {
        //                 this.items.push(response.data[i])
        //             }
        //         })
        //         .catch(error => {
        //             console.log(JSON.stringify(error))
        //         })
        // },
        itemChange() {
            // Find the selected item from the items array
            const selectedItemName = this.item.name.name;
            const selectedItem = this.items.find((item) => item.name === selectedItemName);

            // Update the buy_price field with the selected item's buy_price
            if (selectedItem) {
                this.item.buy_price = selectedItem.buy_price;
                this.item.sell_price = selectedItem.sell_price;
                this.item.item_code = selectedItem.item_code;
                this.item.source_id = selectedItem.id;
                this.item.id = selectedItem.id;
            }

            // Emit an event to notify the parent component about the updated item
            this.$emit('itemUpdated', this.item);
            this.$emit('updatePrice', this.item);
        },
        itemUpdate() {
            // Emit an event to notify the parent component about the updated item
            this.$emit('itemUpdated', this.item);
            this.$emit('updatePrice', this.item);
        },
        deleteItem() {
            // Emit an event to notify the parent component about the deletion
            this.$emit('deleteItem', this.item.item_num)
        },
    },
    computed: {
        gross_amount() {
            const buy_price = this.item.buy_price
            const quantity = this.item.quantity

            this.item.gross_amount = buy_price * quantity

            this.item.gross_amount = Number(this.item.gross_amount.toFixed(2))

            // this.$emit('updatePrice', this.item)

            return this.item.gross_amount.toFixed(2)
        }
    }
}
</script>